if ('define' in window) {
define("discourse/theme-24/raw-templates/list/topic-list-item", ["exports", "discourse/lib/raw-handlebars", "discourse/lib/raw-templates"], function (_exports, _rawHandlebars, _rawTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
      {{#if bulkSelectEnabled}}
        <td class="bulk-select">
          <input type="checkbox" class="bulk-select">
        </td>
      {{/if}}
  
      {{!--
        The `~` syntax strip spaces between the elements, making it produce
        `<a class=topic-post-badges>Some text</a><span class=topic-post-badges>`,
        with no space between them.
        This causes the topic-post-badge to be considered the same word as "text"
        at the end of the link, preventing it from line wrapping onto its own line.
      --}}
      <td class='main-link clearfix' colspan="1">
        <span class='link-top-line'>
          {{~raw-plugin-outlet name="topic-list-before-status"}}
          {{~raw "topic-status" topic=topic}}
          {{~topic-link topic class="raw-link raw-topic-link"}}
          {{~#if topic.featured_link}}
          {{~topic-featured-link topic}}
          {{~/if}}
          {{~raw-plugin-outlet name="topic-list-after-title"}}
          {{~raw "list/unread-indicator" includeUnreadIndicator=includeUnreadIndicator 
                                         topicId=topic.id 
                                         unreadClass=unreadClass~}}
          {{~#if showTopicPostBadges}}
          {{~raw "topic-post-badges" unread=topic.unread newPosts=topic.displayNewPosts unseen=topic.unseen url=topic.lastUnreadUrl newDotText=newDotText}}
          {{~/if}}
        </span>
        <div class="link-bottom-line">
          {{#unless hideCategory}}
            {{#unless topic.isPinnedUncategorized}}
              {{category-link topic.category}}
            {{/unless}}
          {{/unless}}
          {{discourse-tags topic mode="list" tagsForUser=tagsForUser}}
          {{raw "list/action-list" topic=topic postNumbers=topic.liked_post_numbers className="likes" icon="heart"}}
        </div>
        {{#if expandPinned}}
          {{raw "list/topic-excerpt" topic=topic}}
        {{/if}}
      </td>
  
  
  
      {{raw "list/posts-count-column" topic=topic}}
  
      {{#if showLikes}}
        <td class="num likes">
          {{#if hasLikes}}
            <a href='{{topic.summaryUrl}}'>
              {{number topic.like_count}} {{d-icon "heart"}}
            </a>
          {{/if}}
        </td>
      {{/if}}
  
      {{#if showOpLikes}}
        <td class="num likes">
          {{#if hasOpLikes}}
            <a href='{{topic.summaryUrl}}'>
              {{number topic.op_like_count}} {{d-icon "heart"}}
            </a>
          {{/if}}
        </td>
      {{/if}}
  
      <td class="num views {{topic.viewsHeat}}">{{number topic.views numberKey="views_long"}}</td>
  
      {{raw "list/activity-column" topic=topic class="num" tagName="td"}}
  
  */

  let template = (0, _rawHandlebars.template)({
    "1": function (container, depth0, helpers, partials, data) {
      return "  <td class=\"bulk-select\">\n    <input type=\"checkbox\" class=\"bulk-select\">\n  </td>\n";
    },
    "3": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return container.escapeExpression((lookupProperty(helpers, "topic-featured-link") || depth0 && lookupProperty(depth0, "topic-featured-link") || container.hooks.helperMissing).call(depth0 != null ? depth0 : container.nullContext || {}, "topic", {
        "name": "topic-featured-link",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 21,
            "column": 4
          },
          "end": {
            "line": 21,
            "column": 34
          }
        }
      }));
    },
    "5": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return container.escapeExpression((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || container.hooks.helperMissing).call(depth0 != null ? depth0 : container.nullContext || {}, "topic-post-badges", {
        "name": "raw",
        "hash": {
          "newDotText": "newDotText",
          "url": "topic.lastUnreadUrl",
          "unseen": "topic.unseen",
          "newPosts": "topic.displayNewPosts",
          "unread": "topic.unread"
        },
        "hashTypes": {
          "newDotText": "PathExpression",
          "url": "PathExpression",
          "unseen": "PathExpression",
          "newPosts": "PathExpression",
          "unread": "PathExpression"
        },
        "hashContexts": {
          "newDotText": depth0,
          "url": depth0,
          "unseen": depth0,
          "newPosts": depth0,
          "unread": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 28,
            "column": 4
          },
          "end": {
            "line": 28,
            "column": 149
          }
        }
      }));
    },
    "7": function (container, depth0, helpers, partials, data) {
      var stack1,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return (stack1 = lookupProperty(helpers, "unless").call(depth0 != null ? depth0 : container.nullContext || {}, "topic.isPinnedUncategorized", {
        "name": "unless",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(8, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 33,
            "column": 6
          },
          "end": {
            "line": 35,
            "column": 17
          }
        }
      })) != null ? stack1 : "";
    },
    "8": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return "        " + container.escapeExpression((lookupProperty(helpers, "category-link") || depth0 && lookupProperty(depth0, "category-link") || container.hooks.helperMissing).call(depth0 != null ? depth0 : container.nullContext || {}, "topic.category", {
        "name": "category-link",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 34,
            "column": 8
          },
          "end": {
            "line": 34,
            "column": 40
          }
        }
      })) + "\n";
    },
    "10": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return "    " + container.escapeExpression((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || container.hooks.helperMissing).call(depth0 != null ? depth0 : container.nullContext || {}, "list.topic-excerpt", {
        "name": "raw",
        "hash": {
          "topic": "topic"
        },
        "hashTypes": {
          "topic": "PathExpression"
        },
        "hashContexts": {
          "topic": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 41,
            "column": 4
          },
          "end": {
            "line": 41,
            "column": 44
          }
        }
      })) + "\n";
    },
    "12": function (container, depth0, helpers, partials, data) {
      var stack1,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "  <td class=\"num likes\">\n" + ((stack1 = lookupProperty(helpers, "if").call(depth0 != null ? depth0 : container.nullContext || {}, "hasLikes", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(13, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 51,
            "column": 4
          },
          "end": {
            "line": 55,
            "column": 11
          }
        }
      })) != null ? stack1 : "") + "  </td>\n";
    },
    "13": function (container, depth0, helpers, partials, data) {
      var alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.escapeExpression,
        alias3 = container.hooks.helperMissing,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "      <a href='" + alias2(lookupProperty(helpers, "get").call(alias1, "topic.summaryUrl", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 52,
            "column": 15
          },
          "end": {
            "line": 52,
            "column": 35
          }
        }
      })) + "'>\n        " + alias2((lookupProperty(helpers, "number") || depth0 && lookupProperty(depth0, "number") || alias3).call(alias1, "topic.like_count", {
        "name": "number",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 53,
            "column": 8
          },
          "end": {
            "line": 53,
            "column": 35
          }
        }
      })) + " " + alias2((lookupProperty(helpers, "d-icon") || depth0 && lookupProperty(depth0, "d-icon") || alias3).call(alias1, "heart", {
        "name": "d-icon",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 53,
            "column": 36
          },
          "end": {
            "line": 53,
            "column": 54
          }
        }
      })) + "\n      </a>\n";
    },
    "15": function (container, depth0, helpers, partials, data) {
      var stack1,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "  <td class=\"num likes\">\n" + ((stack1 = lookupProperty(helpers, "if").call(depth0 != null ? depth0 : container.nullContext || {}, "hasOpLikes", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(16, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 61,
            "column": 4
          },
          "end": {
            "line": 65,
            "column": 11
          }
        }
      })) != null ? stack1 : "") + "  </td>\n";
    },
    "16": function (container, depth0, helpers, partials, data) {
      var alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.escapeExpression,
        alias3 = container.hooks.helperMissing,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "      <a href='" + alias2(lookupProperty(helpers, "get").call(alias1, "topic.summaryUrl", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 62,
            "column": 15
          },
          "end": {
            "line": 62,
            "column": 35
          }
        }
      })) + "'>\n        " + alias2((lookupProperty(helpers, "number") || depth0 && lookupProperty(depth0, "number") || alias3).call(alias1, "topic.op_like_count", {
        "name": "number",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 63,
            "column": 8
          },
          "end": {
            "line": 63,
            "column": 38
          }
        }
      })) + " " + alias2((lookupProperty(helpers, "d-icon") || depth0 && lookupProperty(depth0, "d-icon") || alias3).call(alias1, "heart", {
        "name": "d-icon",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 63,
            "column": 39
          },
          "end": {
            "line": 63,
            "column": 57
          }
        }
      })) + "\n      </a>\n";
    },
    "compiler": [8, ">= 4.3.0"],
    "main": function (container, depth0, helpers, partials, data) {
      var stack1,
        alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.hooks.helperMissing,
        alias3 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "bulkSelectEnabled", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(1, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 2,
            "column": 0
          },
          "end": {
            "line": 6,
            "column": 7
          }
        }
      })) != null ? stack1 : "") + "\n<td class='main-link clearfix' colspan=\"1\">\n  <span class='link-top-line'>" + alias3((lookupProperty(helpers, "raw-plugin-outlet") || depth0 && lookupProperty(depth0, "raw-plugin-outlet") || alias2).call(alias1, {
        "name": "raw-plugin-outlet",
        "hash": {
          "name": "topic-list-before-status"
        },
        "hashTypes": {
          "name": "StringLiteral"
        },
        "hashContexts": {
          "name": depth0
        },
        "types": [],
        "contexts": [],
        "data": data,
        "loc": {
          "start": {
            "line": 17,
            "column": 4
          },
          "end": {
            "line": 17,
            "column": 58
          }
        }
      })) + alias3((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || alias2).call(alias1, "topic-status", {
        "name": "raw",
        "hash": {
          "topic": "topic"
        },
        "hashTypes": {
          "topic": "PathExpression"
        },
        "hashContexts": {
          "topic": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 18,
            "column": 4
          },
          "end": {
            "line": 18,
            "column": 39
          }
        }
      })) + alias3((lookupProperty(helpers, "topic-link") || depth0 && lookupProperty(depth0, "topic-link") || alias2).call(alias1, "topic", {
        "name": "topic-link",
        "hash": {
          "class": "raw-link raw-topic-link"
        },
        "hashTypes": {
          "class": "StringLiteral"
        },
        "hashContexts": {
          "class": depth0
        },
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 19,
            "column": 4
          },
          "end": {
            "line": 19,
            "column": 57
          }
        }
      })) + ((stack1 = lookupProperty(helpers, "if").call(alias1, "topic.featured_link", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(3, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 20,
            "column": 4
          },
          "end": {
            "line": 22,
            "column": 12
          }
        }
      })) != null ? stack1 : "") + alias3((lookupProperty(helpers, "raw-plugin-outlet") || depth0 && lookupProperty(depth0, "raw-plugin-outlet") || alias2).call(alias1, {
        "name": "raw-plugin-outlet",
        "hash": {
          "name": "topic-list-after-title"
        },
        "hashTypes": {
          "name": "StringLiteral"
        },
        "hashContexts": {
          "name": depth0
        },
        "types": [],
        "contexts": [],
        "data": data,
        "loc": {
          "start": {
            "line": 23,
            "column": 4
          },
          "end": {
            "line": 23,
            "column": 56
          }
        }
      })) + alias3((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || alias2).call(alias1, "list.unread-indicator", {
        "name": "raw",
        "hash": {
          "unreadClass": "unreadClass",
          "topicId": "topic.id",
          "includeUnreadIndicator": "includeUnreadIndicator"
        },
        "hashTypes": {
          "unreadClass": "PathExpression",
          "topicId": "PathExpression",
          "includeUnreadIndicator": "PathExpression"
        },
        "hashContexts": {
          "unreadClass": depth0,
          "topicId": depth0,
          "includeUnreadIndicator": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 24,
            "column": 4
          },
          "end": {
            "line": 26,
            "column": 61
          }
        }
      })) + ((stack1 = lookupProperty(helpers, "if").call(alias1, "showTopicPostBadges", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(5, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 27,
            "column": 4
          },
          "end": {
            "line": 29,
            "column": 12
          }
        }
      })) != null ? stack1 : "") + "  </span>\n  <div class=\"link-bottom-line\">\n" + ((stack1 = lookupProperty(helpers, "unless").call(alias1, "hideCategory", {
        "name": "unless",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(7, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 32,
            "column": 4
          },
          "end": {
            "line": 36,
            "column": 15
          }
        }
      })) != null ? stack1 : "") + "    " + alias3((lookupProperty(helpers, "discourse-tags") || depth0 && lookupProperty(depth0, "discourse-tags") || alias2).call(alias1, "topic", {
        "name": "discourse-tags",
        "hash": {
          "tagsForUser": "tagsForUser",
          "mode": "list"
        },
        "hashTypes": {
          "tagsForUser": "PathExpression",
          "mode": "StringLiteral"
        },
        "hashContexts": {
          "tagsForUser": depth0,
          "mode": depth0
        },
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 37,
            "column": 4
          },
          "end": {
            "line": 37,
            "column": 64
          }
        }
      })) + "\n    " + alias3((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || alias2).call(alias1, "list.action-list", {
        "name": "raw",
        "hash": {
          "icon": "heart",
          "className": "likes",
          "postNumbers": "topic.liked_post_numbers",
          "topic": "topic"
        },
        "hashTypes": {
          "icon": "StringLiteral",
          "className": "StringLiteral",
          "postNumbers": "PathExpression",
          "topic": "PathExpression"
        },
        "hashContexts": {
          "icon": depth0,
          "className": depth0,
          "postNumbers": depth0,
          "topic": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 38,
            "column": 4
          },
          "end": {
            "line": 38,
            "column": 110
          }
        }
      })) + "\n  </div>\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "expandPinned", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(10, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 40,
            "column": 2
          },
          "end": {
            "line": 42,
            "column": 9
          }
        }
      })) != null ? stack1 : "") + "</td>\n\n\n\n" + alias3((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || alias2).call(alias1, "list.posts-count-column", {
        "name": "raw",
        "hash": {
          "topic": "topic"
        },
        "hashTypes": {
          "topic": "PathExpression"
        },
        "hashContexts": {
          "topic": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 47,
            "column": 0
          },
          "end": {
            "line": 47,
            "column": 45
          }
        }
      })) + "\n\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "showLikes", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(12, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 49,
            "column": 0
          },
          "end": {
            "line": 57,
            "column": 7
          }
        }
      })) != null ? stack1 : "") + "\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "showOpLikes", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(15, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 59,
            "column": 0
          },
          "end": {
            "line": 67,
            "column": 7
          }
        }
      })) != null ? stack1 : "") + "\n<td class=\"num views " + alias3(lookupProperty(helpers, "get").call(alias1, "topic.viewsHeat", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 69,
            "column": 21
          },
          "end": {
            "line": 69,
            "column": 40
          }
        }
      })) + "\">" + alias3((lookupProperty(helpers, "number") || depth0 && lookupProperty(depth0, "number") || alias2).call(alias1, "topic.views", {
        "name": "number",
        "hash": {
          "numberKey": "views_long"
        },
        "hashTypes": {
          "numberKey": "StringLiteral"
        },
        "hashContexts": {
          "numberKey": depth0
        },
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 69,
            "column": 42
          },
          "end": {
            "line": 69,
            "column": 87
          }
        }
      })) + "</td>\n\n" + alias3((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || alias2).call(alias1, "list.activity-column", {
        "name": "raw",
        "hash": {
          "tagName": "td",
          "class": "num",
          "topic": "topic"
        },
        "hashTypes": {
          "tagName": "StringLiteral",
          "class": "StringLiteral",
          "topic": "PathExpression"
        },
        "hashContexts": {
          "tagName": depth0,
          "class": depth0,
          "topic": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 71,
            "column": 0
          },
          "end": {
            "line": 71,
            "column": 67
          }
        }
      })) + "\n";
    },
    "useData": true
  });
  (0, _rawTemplates.addRawTemplate)("list/topic-list-item", template, {
    themeId: 24,
    themeName: "Hide Users Column",
    hasModernReplacement: false
  });
  var _default = _exports.default = template;
});
}
